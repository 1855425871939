const auditRoutes = [
  {
    path: "/nice",
    name: "nice",
    component: () =>
      import(/* webpackChunckName: "contact" */ "@/views/nice/NiceView.vue"),
  },
];

export default auditRoutes;
