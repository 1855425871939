const search = {
  tab: {
    sms: "SMSメッセージ",
    qr: "QRコード(イメージ)",
  },
  summary: {
    analysis: "分析結果",
    scan_at: "最近のスキャン日",
    status: "状態",
    tags: "タギング",
    issuer: "発行者",
    subject: "発行対象",
    not_before: "発行日",
    not_after: "満了日",
    version: "TLS バージョン",
    judgment_basis: "最終判断根拠(By ‘Generative AI’)",
    analysis_by_engine: "エンジン別分析結果及び判断根拠",
    detection: "エンジンごとの検出情報",
    detection_invaild_id: "アイテムID情報が無効です。",
    screenshot: "スクリーンショット",
    doamin_title: "ドメイン",
    domain: "ドメイン名",
    registered_date: "登録日",
    registrant: "登録者",
    registrant_address: "登録者アドレス",
    updated_date: "最近の更新日",
    expiration_date: "満了日",
    name_servers: "ネームサーバー",
    ip_address: "IPアドレス",
    nation: "国",
    host_name: "ホスト名",
    screenshot_none: "スクリーンショットの画像がありません。",
    host_ip_none: "ホストIP情報がありません。",
    domain_none: "ドメイン情報がありません。",
    certificate_none: "証明書情報がありません。",
    detection_none: "検出情報はありません。",
    hash_none: "ハッシュ情報は存在しません。",
    certificate_title: "証明書",
    show_brief: "簡潔に表示",
    show_all: "すべて表示",
  },
  resources: {
    tips: "該当するハッシュファイルを含むDomain（URL）情報を表示します。",
    resource_info: "リソース情報",
  },
  scanning: {
    url_scanning: "URLをスキャンしています。",
    wait_a_moment: "しばらくお待ちください。",
  },
  audit: {
    title: "Audit Log",
    changes: "監査ログ",
  },
  news: {
    enter_title: "タイトルを入力してください",
    enter_link: "リンクを入力してください",
    enter_published_date: "公開日を入力してください",
    enter_source: "出典を入力してください",
    enter_lang: "言語を選択してください",
  },
  detection: {
    enter_item_id: "IDを入力してください。",
    enter_tags: "タグを選択してください。",
    enter_description: "説明を入力してください。",
    enter_domain: "ドメインを入力してください。",
    enter_url: "URLを入力してください。",
    enter_source: "ソースを入力してください。",
    enter_source_id: "ソースIDを入力してください。",
    enter_classification: "分類を入力してください。",
    enter_judgment_basis: "判断基準を入力してください。",
  },
  update: {
    before: "更新前",
    after: "更新後",
    screenshot_dialog_title: "スクリーンショット編集",
    screenshot_dialog_no_image: "画像がありません",
    screenshot_dialog_select_image: "画像を選択してください",
    screenshot_dialog_choose_image: "画像選択",
    screenshot_dialog_confirm_download: "画像をダウンロードしますか？",
    screenshot_dialog_drop_image: "または、画像をここにドロップしてください。",
    no_file_selected: "選択されたファイルがありません",
  },
};

export default search;
