import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import homeRoutes from "./homeRoutes";
import loginRoutes from "./loginRoutes";
import { useAuthStore } from "@/store/auth";

const routes: Array<RouteRecordRaw> = [
  ...homeRoutes, // homeRoutes 배열을 병합
  ...loginRoutes, // loginRoutes 배열을 병합
  {
    path: "/:catchAll(.*)",
    redirect: { name: "home" }, // 잘못된 경로를 home으로 리디렉션
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes, // 라우트를 설정
  scrollBehavior() {
    return { top: 0 }; // 페이지 이동 시 상단으로 스크롤
  },
});

// 전역 네비게이션 가드 (모든 경로에 적용)
router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();

  if (to.name === "login") {
    // 이미 로그인되어 있으면 홈으로 리디렉션
    if (authStore.accessJwt) {
      return next({ name: "dashboard1" });
    }
    return next();
  }

  if (
    to.name !== "forgot" &&
    !to.path.startsWith("/password/reset/") &&
    to.name !== "reset-password"
  ) {
    if (!authStore.accessJwt) {
      return next({ name: "login" });
    }
  }

  try {
    next();
  } catch (error) {
    console.error("토큰 갱신 실패:", error);
    authStore.requestLogout();

    return next({ name: "login" }); // 갱신 실패 시 로그인 페이지로 리디렉션
  }
});

export default router;
