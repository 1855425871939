const supportRoutes = [
  {
    path: "/support/report",
    name: "report",
    component: () =>
      import(
        /* webpackChunckName: "report" */ "@/views/support/ReportView.vue"
      ),
  },
  {
    path: "/support/contact",
    name: "contact",
    component: () =>
      import(
        /* webpackChunckName: "contact" */ "@/views/support/ContactView.vue"
      ),
  },
];

export default supportRoutes;
